@import '../../assets/styles/typography';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.header-logo-only {
  nav {
    padding: 0 18px;
    @media (min-width: 1263px) {
      padding: 0 40px;
    }
    @media (min-width: 1920px) {
      padding: 0 100px;
    }
    .logo-container {
      margin: 55px 0;
      .logo {
        img {
          width: 180px;
          vertical-align: middle;
        }
        &.center {
          text-align: center;
        }
        &.right {
          margin-left: calc(100% - 180px);
        }
      }
    }
  }
}
