@import '../../assets/styles/typography';

.search-results {
  display: block;
  margin: 15px 0 0 0;
  width: 100%;

  .search-standard-result {
    padding: 0;
    margin: 40px 0 0px 0px;

    a.search-result-name {
      @include h4();
      text-decoration: none;
    }
    .search-result-description {
      padding: 0 12 0 12;
    }
    .search-result-link {
      word-break: break-all;
      padding: 0 12 0 12;
    }
  }

  .search-result-pagination {
    display: block;
    padding: 100px 0 0 0;
  }
}
