@import '_variables.scss';

html {
  font-size: 10px;
}

body {
  background-color: $white;
  margin: 0;
  padding: 0;
  &::backdrop {
    background-color: #fafafa;
  }
}

@media print {
  body {
    background-color: $white;
  }
}
