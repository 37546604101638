@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.site-search {
  // Conditional Styling for Search Fields on Pages
  .site-search-frame {
    position: relative;
    padding: 20px 10px;
    background: linear-gradient(99deg, rgba(77, 16, 74, 1) 0%, rgba(43, 6, 34, 1) 100%);
    border-bottom: 12px solid $primary-green;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 3px;
      background: #fff;
    }
    &.small-width {
      max-width: 568px;
    }
    &.medium-width {
      max-width: 768px;
    }
    &.large-width {
      max-width: 1168px;
    }
    .site-search-form-wrapper {
      width: 90%;
      margin: 0 auto;
      form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .search-field-wrapper {
          flex-grow: 1;
        }
        .site-search-search-button {
          margin-right: 25px;
        }
      }
    }
    @include min-screen(small) {
      border-bottom: none;
      border-left: 3px solid #fff;
      &:before {
        content: none;
      }
    }
  }
  .site-search-form-wrapper {
    background-color: #fff;
    border: 1px solid $cool-grey-1;
    border-radius: 20px;
    display: flex;
    padding-left: 15px;
    width: 280px;
    height: 35px;
    &:focus-within {
      border: 1px solid $primary-green;
    }
    form {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .search-field-wrapper {
      display: inline;
      flex-grow: 1;
    }
    .site-search-field {
      padding: 0;
      // transition: theme.transitions.create('width');
      width: 99%;
      font-size: 16px;
      height: 35px;
      border: none;
      background: none;
      margin: 0 8px 0 0;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #666d70;
        opacity: 1;
      }
    }
    .site-search-search-button {
      display: inline-block;
      margin-right: 15px;
      input {
        background: url(../../assets/images/iconography/icon-search-magnifer.svg);
        background-position: center;
        background-repeat: no-repeat;
        text-indent: -9999px;
        width: 15px;
        border: none;
        cursor: pointer;
        z-index: 1000;
        &:focus {
          outline-offset: 0px;
        }
      }
    }
    .site-search-reset-button {
      display: inline-block;
      border-right: 1px solid $cool-grey-1;
      padding-right: 10px;
      margin-right: 10px;
      width: 18px;
      input {
        background: url(../../assets/images/iconography/icon-search-close.svg);
        background-position: center;
        background-repeat: no-repeat;
        text-indent: -9999px;
        margin-right: 5px;
        cursor: pointer;
        border: none;
        z-index: 1000;
        &:focus {
          outline-offset: 0px;
        }
      }
    }
  }
  @include min-screen(small) {
    &.site-search-border {
      border-left: 12px solid $primary-green;
    }
  }
}
