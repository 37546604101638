@import '../../assets/styles/typography';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.emergency-alert-wrapper {
  transition: transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s;
  &.high {
    background-color: $alert-red;
    .emergency-alert-carousel {
      button {
        color: $white;
      }
    }
  }
  &.medium {
    background-color: $alert-yellow;
    .emergency-alert-carousel {
      button, button span {
        color: $cool-grey-11 !important;
      }
    }
    .alert-close .emergency-alert-close .MuiIconButton-label .MuiSvgIcon-root{
      color: $cool-grey-11;
    }
  }
  &.low {
    background-color: $alert-green;
    .emergency-alert-carousel {
      button {
        color: $white;
      }
    }
  }
  .emergency-alert-carousel {
    .previous-alert {
      position: absolute;
      z-index: 9000;
      left: 5px;
      top: 70px;
      @include min-screen(large) {
        top: 0;
        left: 20px;
      }
    }
    .next-alert {
      position: absolute;
      z-index: 9000;
      right: 20px;
      top: 70px;
      @include min-screen(large) {
        top: 0;
        right: 5px;
      }
    }
  }
  .emergency-alert {
    display: block;
    padding: 19px 20px;
    height: 108px;

    @include min-screen(large) {
      flex-direction: row;
      display: flex;
      align-items: flex-start;
      padding: 10px 98px;
      height: inherit;
    }
    &.high {
      background-color: $alert-red;
    }
    &.medium {
      background-color: $alert-yellow;
      .alert-details {
        color: $cool-grey-11;
        .alert-content {
          .alert-link {
            a {
              color: $cool-grey-11;
            }
          }
        }
      }
      .alert-title {
        p {
          color: $cool-grey-11;
        }
      }
      .alert-close {
        .emergency-alert-close {
          .MuiSvgIcon-root {
            color: $cool-grey-11 !important;
          }
        }
      }
      .alert-icon {
        .MuiSvgIcon-root {
          color: $cool-grey-11;
        }
      }
    }
    &.low {
      background-color: $alert-green;
    }
    .alert-icon {
      display: inline-block;
      line-height: 1;
      @include min-screen(large) {
      }
      margin-right: 10px;
      .MuiSvgIcon-root {
        font-size: 3.5rem;
        color: $white;
      }
    }
    .alert-title {
      display: inline-block;
      vertical-align: top;
      margin-top: 7px;
      p {
        @include heading-font();
        font-size: 18px;
        color: $white;
        margin: 0;
        text-transform: uppercase;
      }
    }
    .alert-details {
      display: block;
      @include min-screen(large) {
        flex-direction: row;
        display: flex;
        align-items: flex-start;
      }
      @include body-font();
      font-weight: 500;
      color: $white;
      font-size: 18px;
      .alert-content {
        overflow-x: auto;
        display: block;
        height: 75px;
        @include min-screen(large) {
          overflow: hidden;
          display: flex;
          height: inherit;
          align-items: flex-start;
        }
        .alert-message {
          margin-left: 45px;
          display: block;
          margin-right: 45px;
          margin-top: 0;
          @include min-screen(large) {
            margin-top: 7px;
            display: inline-block;
            margin-left: 20px;
            margin-right: 0;
          }
          .description p:first-child {
            margin-top: 0;
          }
        }
        .alert-link {
          display: block;
          margin-left: 45px;
          @include min-screen(large) {
            display: inline-block;
            margin-left: 20px;
            margin-top: 7px;
            width: 280px;
          }
          a {
            color: $white;
          }
        }
      }
    }
    .alert-close {
      margin-left: 0;
      position: absolute;
      top: 19px;
      right: 20px;
      @include min-screen(large) {
        margin-left: auto;
        top: inherit;
        right: inherit;
        position: relative;
      }
      .emergency-alert-close {
        font-size: initial;
        padding: 0;
        .MuiIconButton-label {
          display: block;
          .MuiSvgIcon-root {
            font-size: 3.5rem;
            color: $white;
          }
        }
      }
    }
  }
  .item {
    display: none;
    opacity: 0;
    transition-duration: 1s ease;
    & + .item {
      display: none;
    }
    &.active {
      display: block;
      opacity: 1;
      transition-duration: 1s;
      transform: scale(1);
    }
  }
}
