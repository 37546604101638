@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.blog-listing-wrapper {
  .blog-listing-header {
    @include heading-font();
    @include font-size(3);
    display: block;
    margin-left: 20px;
  }
  .blog-listing {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr; /* Ensures each row is the same height */
    gap: 30px;
    padding: 20px;
    @include max-screen(small) {
      grid-template-columns: 1fr; /* 1 item per row on mobile */
    }
    .blogcard > .MuiCard-root {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .blogcard {
      .blog-category {
        color: #161f28;
        font-weight: 600;
        @include font-size(1.6);
        display: block;
      }
      .imagecard-image {
        max-width: 100%;
        max-height: 100%;
        aspect-ratio: 16/9;
      }
      .blogcard-heading {
        font-weight: 700;
        @include font-size(2.2);
        display: block;
        color: $primary-purple;
      }
      .blogcard-publishing-date {
        font-weight: 400;
        @include font-size(1.6);
        color: #161f28;
        margin-top: 10px;
        display: block;
      }
      .blogcard-link-container {
        font-weight: 700;
        @include font-size(1.8);
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: auto;

        .blogcard-link {
          margin-right: 18px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
