@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.job-posting-details {
  margin-top: 24.12px;
  @media (min-width: 1263px) {
    margin-top: 34.8px;
  }
  .job-posting-details-category {
    @include h3();
    padding: 0 18px;
    margin-bottom: -15px;
    display: block;
    @media (min-width: 1263px) {
      padding: 0 100px;
    }
  }
  h1 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .job-posting-details-banner-wrapper {
    display: block;
    @include min-screen(large) {
      display: flex;
    }
    .job-posting-details-banner-details {
      width: 100%;
      padding: 30px 0;
      background: url('../../assets/images/iconography/iconography-white-lines-on-grey.svg')
          no-repeat bottom right,
        linear-gradient(108deg, #ffffff 0%, #efefef 100%) padding-box;

      .job-posting-details-banner-details-innner {
        padding: 0 18px 30px;
        @media (min-width: 1263px) {
          padding: 0 100px;
        }

        .job-posting-details-specialty-subheading {
          @include h2();
          margin: 20px 0 0;
          display: block;
        }
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          li {
            @include font-size(2);
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .job-posting-application-link {
    text-align: center;
    display: block;
    margin-top: -30px;
    text-decoration: none;
  }
  .job-posting-details-biography {
    margin: 40px 0;
    .job-posting-details-biography-wrapper {
      padding: 0 18px;
      @media (min-width: 1263px) {
        padding: 0 100px;
      }
      h2 {
        margin: 0;
      }
      .details-section {
        margin-top: 60px;
      }
    }
  }
}
