@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/typography';

.scInsertionHandleCenter {
  box-sizing: content-box !important;
}
.form-container {
  .request-denied {
    color: $alert-red;
  }
}

.upmc-form {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  width: 100%;
  form {
    padding: 25px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  input,
  select {
    vertical-align: middle;
  }

  fieldset {
    border-color: #e0e0e0;
    border-style: solid;
    border-width: 0px;
    padding: 10px;
    margin: 10px 0 0 0;
  }

  legend {
    color: #606060;
    font-size: 14px;
  }

  label {
    @include heading-font();
    width: 100%;
    float: left;
    text-align: left;
    margin: 20px 0 5px;
    padding: 0%;
    cursor: text;
    font-weight: 700;
  }

  .checkbox-label,
  .radio-label {
    span {
      font-weight: normal;
    }
  }

  input,
  select,
  textarea {
    @include body-font();
    @include font-size(1.8);
    width: 100%;
    padding: 10px;
    border: 1px solid $iron-grey;
    border-radius: 5px;
    box-sizing: border-box;
    resize: vertical;
    color: #000;
    background-color: $concrete;

    &:focus {
      border: 1px solid $accent-purple;
      outline: none;
      box-shadow: none;
    }
    ::placeholder {
      color: $cool-grey-7;
    }
  }

  select {
    &:focus {
      border-radius: 5px 5px 0 0;
    }
  }

  input[type='submit'],
  button[type='submit'] {
    @include font-size(1.8);
    background-color: $primary-green;
    color: $white;
    padding: 12px 32px;
    border-radius: 25px;
    box-shadow: none;
    border-style: none;
    margin: 25px auto 0;
    display: block;
    width: 100%;
    @include min-screen(medium) {
      max-width: 256px;
    }

    &:focus {
      outline: 2px solid $accent-purple;
    }

    &:disabled {
      background-color: #dbd9d6;
      cursor: default;
    }
    &:hover {
      background-color: #1c723c;
      &:disabled {
        background-color: #dbd9d6;
      }
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    width: 15px;
    margin: 0 10px 0 15px;
  }
  /*-------------------------------------- Validation Section -- */
  .form-errors {
    display: none;
  }
  .validation-summary-errors {
    ul {
      list-style-type: none;
      padding: 0;

      li {
        color: red;
      }
    }
  }

  .valid {
    border: 1px solid $accent-green;

    &:focus {
      border: 1px solid $accent-green;
    }
  }

  .field-validation-error,
  .invalid {
    color: $alert-red;
    display: block;
  }
  label.invalid {
    color: $cool-grey-11;
    + input {
      border: 1px solid $alert-red;
      &:focus {
        border: 1px solid $accent-purple;
      }
    }
  }

  .input-validation-error {
    border: 1px solid $alert-red;

    &:focus {
      border: 1px solid $alert-red;
    }
  }
  /*--------------------------------------END Validation Section -- */
  .calendar {
    float: left;
  }

  .radiobuttonlist {
    margin: 0;
    width: 100%;
  }

  .radiobuttonlist tr td {
    padding: 0;
  }

  .radiobuttonlist input {
    width: 12px;
    margin: 15px 10px 0 5px;
  }

  .radiobuttonlist label {
    margin-left: 3px !important;
    margin-right: 10px !important;
  }

  input#body_txtOther {
    width: 50%;
    margin: 0px;
  }

  :-ms-input-placeholder {
    opacity: 0.5;
  }

  .g-recaptcha {
    margin: 24px 0 0 0;

    div {
      height: inherit !important;
    }
  }

  .custom-field {
    padding: 20px 0 0;
  }
}

/*Loading Annimation*/
.loading-wrapper {
  display: none;
  width: 100%;
  position: absolute;
  margin-top: -250px;
  left: 0;
  z-index: 1000;

  .loadingAnimation {
    position: relative;
    z-index: 10000;
    background-color: #fff;
    width: 166px;
    border-radius: 5px;
    padding: 0 20px;
    margin: 0 auto;

    img {
      display: block;
      margin: 0 auto;
    }

    p {
      text-align: center;
    }
  }
}
