@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.blog-category-listing-wrapper {
  .blog-listing-header {
    @include heading-font();
    @include font-size(3);
    display: block;
    margin-left: 20px;
  }

  .blog-category-pagination {
    justify-items: center;
  }
  .blog-category-listing {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px;

    .blog-category-card > .MuiCard-root {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .blog-category-card {
      .blog-category {
        color: #161f28;
        font-weight: 600;
        @include font-size(1.6);
        display: block;
      }

      .imagecard-aa {
        width: 100%;
        height: 100%;
        display: flex;
      }

      .imagecard-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .blogcard-heading-link {
        text-decoration: none;
        .blogcard-heading {
          font-weight: 700;
          @include font-size(2.2);
          display: block;
          color: $primary-purple;
        }
      }

      .blog-post-sumary {
        @include font-size(1.8);

        margin-left: 15px;
        margin-right: 15px;
      }

      .blogcard-publishing-date {
        font-weight: 400;
        @include font-size(1.6);
        color: #161f28;
        margin-top: 10px;
        display: block;
      }
      .blog-card-content-wrapper {
        display: flex;
        flex-direction: column;
        .blogcard-link-container {
          font-weight: 700;
          @include font-size(1.8);
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: auto;

          .blogcard-link {
            margin-right: 18px;
            margin-bottom: 10px;
          }
        }
      }

      @include max-screen(small) {
        .blog-card-image-wrapper {
          width: 100%;
        }

        .blog-card-content-wrapper {
          width: 100%;
        }

        .MuiCard-root {
          flex-direction: column;
        }

        .imagecard-aa {
          height: auto;
        }

        .imagecard-image {
          height: auto;
        }
      }
    }
  }
}
