@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.container {
  &.breadcrumbs-wrapper {
    display: block;
    min-height: 49px;
    padding-top: 15px;
    .breadcrumbs {
      .navigation {
        @include min-screen(large) {
          padding-left: 90px;
        }
        padding-left: 15px;
        color: $dark-cyan;
        padding-top: 0px;
        padding-bottom: 0px;

        li {
          margin-top: 2px;
          margin-bottom: 2px;
          padding-left: 6px;
          padding-right: 0px;
          display: inline-block;
          width: auto;
          div {
            display: inline-block;
          }
          &.hidden-li {
            display: none;
          }
          &.ellipses-hidden {
            display: none;
          }
          &.ellipses-active a {
            text-decoration: none;
          }
          &.current-page {
            color: $breadcrumb-current;
          }
        }

        a {
          text-decoration: none;
          font-family: Montserrat, sans-serif;
          letter-spacing: 0.01em;
          font-size: 14px;
          font-size: 1.4rem;
          color: $dark-cyan;
          line-height: 1.2;
          margin-right: 5px;
          &:hover {
            text-decoration: underline;
            color: $accent-green;
          }
        }

        span {
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          font-size: 1.4rem;
          letter-spacing: 0.01em;
        }

        button {
          padding-left: 6px;
        }
      }
    }
  }
}

@include max-screen(medium) {
  .container.breadcrumbs-wrapper {
    .breadcrumbs {
      .navigation {
        margin-bottom: 0;
        padding-left: 9px;
        li {
          display: inline;
          padding-left: 2px;
        }

        a {
          font-size: 16px;
          font-size: 1.6rem;
          padding: 2px 5px;
        }

        span {
          &.ellipsed {
            font-size: 16px;
            font-size: 1.6rem;
            padding: 0 10px;
          }
        }
      }
    }
  }
}
