@import '../../assets/styles/mixins';
.subsitenav {
  color: purple;
}

@include min-screen(medium) {
  ul.linklist-special{
      column-count: 2;
  }
  ul.linklist.linklist-special ul {
    column-count: 1;
  }
}