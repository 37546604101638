@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/_variables.scss';
@import '../../../assets/styles/_typography.scss';
@import '../../../assets/styles/_accessibility.scss';

.headless-consultants-search-page {
  .two-column-content-row {
    .MuiGrid-item {
      padding: 0px;
      margin-bottom: 18px;
    }
  }
  .search-field-wrapper {
    @include min-screen(small) {
      max-width: 768px;
    }
    .search-field-border {
      margin-bottom: 25px;
      .search-field-frame {
        box-sizing: border-box;
        border: none;
        .headless-search-field-input {
          width: calc(100% - 60px);
        }
        .site-search-reset-button {
          display: none;
        }
      }
    }
    &:focus-within {
      .search-field-border {
        .headless-search-field-input {
          width: calc(100% - 99px);
        }
        .site-search-reset-button {
          display: inline-block;
        }
      }
    }
  }

  // Query Summary Styles
  .search-title {
    margin: 15px 0 0 0;
  }
  // Facet Styles
  .facetDesktop {
    @include max-screen(medium) {
      display: none;
    }
    .facet {
      .specialties-title {
        @include h4();
        margin-top: 0;
        margin-bottom: 10px;
      }
      padding: 18px;
      margin-bottom: 40px;
      padding-bottom: 15px;
    }
    div {
      margin-left: 5px;
    }
    input[type='checkbox'] {
      margin-left: -5px;
    }
  }

  .facetMobile {
    @include min-screen(medium) {
      display: none;
    }
    @include max-screen(medium) {
      display: block;
    }
    div {
      margin-left: 5px;
    }
    input[type='checkbox'] {
      margin-left: -5px;
    }
  }
  .facet {
    padding: 18px;
    margin-bottom: 40px;
    .specialties-title {
      @include h4();
      margin-top: 0;
      margin-bottom: 10px;
    }
    .show-more-less-wrapper {
      padding-top: 10px;
      padding-bottom: 20px;
    }
  }
  // Search Results Styles
  .mainresults-area {
    .result-list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      .result-item {
        .search-result-name {
          a {
            @include h4();
            text-decoration: none;
          }
        }
        .search-result-description {
          color: $cool-grey-9;
        }
        .search-result-link {
          padding-bottom: 20px;
          display: block;
          a {
            word-break: break-all;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    @include max-screen(medium) {
      padding: 0 16px 0 0;
    }
    .consultant-summary {
      margin-bottom: 15px;
      @include max-screen(medium) {
        margin-bottom: 30px;
      }
    }
    .search-result-pagination {
      ul {
        margin-top: 35px;
        justify-content: center;
      }
    }
    @include min-screen(medium) {
      width: 100%;
      margin-left: 0;
    }

    .consultant-profile-wrapper {
      padding: 25px;
      border: 1px solid $cool-grey-5;
      border-radius: 4px;
      box-sizing: border-box;
      width: 100%;
      margin-top: 20px;
      background: #ffffff 0% 0% no-repeat padding-box;
      .display-name {
        margin: 0;
        @include max-screen(medium) {
          font-size: 36px;
        }
        @include max-screen(small) {
          font-size: 32px;
        }
      }
      @include max-screen(small) {
        margin-bottom: 20px;
      }
      @media (min-width: 960px) {
        max-width: 100%;
        margin-left: 0;
      }
      padding-bottom: 0px;
      display: flex;
      @include min-screen(small) {
        flex-direction: row;
      }
      .image-container {
        display: block;
        img {
          border: none;
          width: 100%;
        }
      }
      .details-wrapper {
        .contact-location-container {
          margin-top: 15px;
        }

        h6,
        .locations-heading .contact-heading {
          margin-top: 10px;
          margin: 0;
          color: $cool-grey-11;
          display: block;
          line-height: 1.5;
          @include body-font();
          font-weight: bold;
        }
        h3,
        .specialty-heading {
          margin: 0;
          color: $cool-grey-11;
          display: block;
          line-height: 1.5;
          font-weight: 700;
          font-size: 22px;
          .specialty {
            &:not(:last-child)::after {
              content: ', ';
            }
          }
        }
        .consultant-location {
          display: block;
          @include body-font();
          p {
            margin: 0;
          }
        }
        .phone-list {
          list-style-type: none;
          margin: 5px 0 0 0;
          padding: 0;
        }
        .contact-list {
          list-style-type: none;
          margin: 5px 0 0 0;
          padding: 0;
          width: 80%;
          @include max-screen(small) {
            width: 100%;
          }
        }
        a {
          text-decoration: none;
          button {
            margin: 8px 0;
            width: 166px;
            height: 40px;
            font-weight: 600;
          }
        }
        @include min-screen(small) {
          padding-left: 32px;
        }
        .view-profile {
          text-align: right;
        }
      }
    }

    .consultant-profile-wrapper:nth-child(even) {
      background: #f2f6f7 0% 0% no-repeat padding-box;
    }
  }
  //Footer Area Styles
  .footer-area {
    margin-top: 20px;
    .pager {
      button {
        cursor: pointer;
        min-width: 40px;
        border-radius: 5px;
        font-weight: 700;
        &:disabled {
          cursor: inherit;
          color: $cool-grey-11;
        }
      }
      .search-result-pagination {
        .MuiPagination-ul {
          justify-content: center;
        }
      }
    }
  }
}
