// BREAKPOINTS (Media Queries)  =============================================== *
$breakpoints: (
  'tiny': 0px,
  'small': 600px,
  'medium': 960px,
  'large': 1280px,
  'xlarge': 1921px,
);

// Colors  =============================================== *
//Base
$white: #fff;
$black: #000;

// Primary
$primary-purple: #4d104a;
$upmc-purple: #771b61;
$primary-green: #228747;

// Accent
$accent-purple: #904199;
$accent-green: #1c723c;
$accent-dark-purple: #2b0622;

// Greys
$grey: #efefef;
$light-grey: #d5d5d5;
$cool-grey-1: #dbd9d6;
$cool-grey-4: #bdbbbb;
$cool-grey-5: #cccccc;
$cool-grey-7: #9a9a9b;
$cool-grey-9: #666d70;
$cool-grey-11: #363533;
$alto-grey: #dedede;
$concrete: #f3f3f3;
$iron-grey: #e0e1e2;
$silver-foil: #afb0b1;

// Alert System
$alert-red: #bb2253;
$alert-orange: #f47721;
$alert-yellow: #fbaf5d;
$alert-green: #228747;

//Breadcrumbs
$breadcrumb-current: #1B1C1D;

//Tertiary Colors
$rose-red:#BB2253;
$light-orange:#FBAF5D;
$medium-cyan:#0092B4;
$dark-cyan:#00819F;
$dark-purple:#3C1053;
$light-purple:#C7B4E2;
$light-blue:#47C6E6;
$yellow:#CFDB00;
$dark-orange:#F47721;
$coral:#F37F89;

//Purples
$deep-mauve : #bc3dc9;