@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/typography';

.multi-facet-wrapper {
  padding: 18px 18px 15px;

  .category-label {
    display: block;
    margin-left: 25px;
  }
  .category-arrow-icon {
    height: 15px !important;
    width: 15px !important;
    vertical-align: middle;
  }
  .category-arrow-icon-back {
    height: 15px !important;
    width: 15px !important;
    vertical-align: middle;
    margin-left: 40px;
    margin-right: 15px;
  }
  .category-facet-categories {
    padding: 6px 0px;
    margin-left: -18px;
    &:hover {
      background-color: transparent !important;

      .MuiButton-label {
        color: $primary-green;
        text-decoration: underline;
        text-decoration-color: $primary-green;
      }
    }
  }

  .parent-facet-wrapper {
    .location-title {
      @include h4();
      margin-bottom: 0;
    }
  }
  .dependent-facet-wrapper {
    margin-left: 30px !important;
    .dependent-facet {
      margin-bottom: 0;
      margin-left: 2px;
    }
    .facet-title {
      @include h5();
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
