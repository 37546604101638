@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.consultant-listing {
  @include max-screen(medium) {
    padding: 0 16px;
  }
  .consultant-summary {
    margin-bottom: 15px;
    @include max-screen(medium) {
      margin-bottom: 30px;
    }
  }
  .search-result-pagination {
    ul {
      margin-top: 35px;
      justify-content: center;
    }
  }
  @include min-screen(medium) {
    width: 90%;
    margin-left: 75px;
  }
}
.consultant-profile-summary {
  padding: 16px;
  .display-name {
    margin: 0;
    @include max-screen(medium) {
      font-size: 36px;
    }
    @include max-screen(small) {
      font-size: 32px;
    }
  }
  .consultant-profile-wrapper {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    @include min-screen(small) {
      flex-direction: row;
    }
    .image-container {
      img {
        border: 1px solid $cool-grey-7;
        width: 168px;
        @include max-screen(small) {
          width: 260px;
        }
      }
      @include min-screen(small) {
        padding-top: 15px;
      }
    }
    .details-wrapper {
      .consultant-location {
        @include h6();
        display: block;
      }
      h3,
      .consultant-location {
        margin: 0;
        color: $cool-grey-11;
        display: block;

        @include max-screen(medium) {
          line-height: 1.75;
        }
      }
      h3 {
        @include max-screen(large) {
          font-size: 26px;
        }
        @include max-screen(medium) {
          color: $primary-purple;
        }
      }
      span {
        @include max-screen(large) {
          font-size: 18px;
        }
      }
      .phone-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      a {
        text-decoration: none;
        button {
          margin: 8px 0;
          width: 166px;
          height: 50px;
        }
      }
      @include min-screen(small) {
        padding-left: 32px;
      }
    }
  }
}
