@import '_variables.scss';

.cta {
  color: $white;
  background-color: $primary-green;
  padding: 12px 32px 13px 32px;
  // display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  min-width: 64px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Catamaran', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 25px;
  text-transform: inherit;
  text-decoration: none;
  display: block;

  &:hover {
    color:$white;
    background-color: $accent-green;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  &:focus {
    background-color: $light-grey;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  &:visited {
    color:$white;
  }

  &.secondary {
    background-color: $white;
    color: $primary-green;

    :hover,
    :focus {
      background-color: $accent-green;
      color: $white;
    }
  }

  &.disabled {
    background-color: $iron-grey;
    color: $silver-foil;

    :hover,
    :focus {
      background-color: $iron-grey;
      color: $silver-foil;
      outline: none;
      outline-offset: 0;
      cursor: default;
    }
  }
}