@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/typography';

.drilldown-container {
  .MuiIconButton-root {
    color: $cool-grey-11;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .drilldown-header {
    color: $cool-grey-11;
    font-weight: bold;
    background: #efefef;
    button {
      padding-left: 19px;
    }
    .mobile-back-navigation {
      span {
        @include heading-font();
        @include font-size(1.8);
        font-weight: bold;
        color: $cool-grey-11;
        vertical-align: middle;
      }
    }
    .drilldown-closeicon {
      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
  .drilldown-title {
    border-bottom: 1px solid $iron-grey;
    padding: 12px 0 11px 36px;
    a {
      @include heading-font();
      @include font-size(1.8);
      text-decoration: none;
      font-weight: bold;
      color: $accent-green;
    }
  }
  .drilldown-body {
    a {
      color: $black;
      width: 90%;
      text-decoration: none;
    }
    .MuiList-root {
      padding: 0;
      .MuiListItem-root {
        border-bottom: 1px solid $iron-grey;
        padding: 12px 0 11px 51px;
        .MuiListItemText-root {
          margin: 0;
          span {
            @include heading-font();
            @include font-size(1.8);
            font-weight: normal;
            color: $cool-grey-11;
          }
        }
      }
    }
    .MuiIconButtonBase-root {
      border: lightgray solid !important;
      border-width: 1px 0 !important;
    }
    .MuiIconButton-root {
      padding: 0;
    }
  }
}

.drilldown-closeicon {
  float: right;
}

// For future ref
:root {
  --duration: 500ms;
}
.drilldown-wrapper {
  color: black;
  border: 1px solid gray;
  width: 100%;
  margin-right: 30px;
  margin-top: -30px;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 3px 9px gray;
  height: 100%;
}

.drilldown-container {
  position: relative;
  transition: height var(--duration) cubic-bezier(0.7, 0, 0.3, 1);
}
.drilldown-itemsList {
  list-style-type: none;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
}
.drilldown-itemsList li {
  animation-duration: var(--duration);
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  margin: 0;
}
.drilldown-animateOutToRight {
  pointer-events: none;
}
.drilldown-animateOutToRight li {
  animation-name: outToRight;
}
.drilldown-animateOutToLeft {
  pointer-events: none;
}
.drilldown-animateOutToLeft li {
  animation-name: outToLeft;
}
.drilldown-animateInFromLeft li {
  animation-name: inFromLeft;
}
.drilldown-animateInFromRight li {
  animation-name: inFromRight;
}

@-webkit-keyframes outToRight {
  to {
    opacity: 0;
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0);
  }
}
@keyframes outToRight {
  to {
    opacity: 0;
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0);
  }
}
@-webkit-keyframes outToLeft {
  to {
    opacity: 0;
    -webkit-transform: translate3d(-80%, 0, 0);
    transform: translate3d(-80%, 0, 0);
  }
}
@keyframes outToLeft {
  to {
    opacity: 0;
    -webkit-transform: translate3d(-80%, 0, 0);
    transform: translate3d(-80%, 0, 0);
  }
}
@-webkit-keyframes inFromLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-80%, 0, 0);
    transform: translate3d(-80%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes inFromLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-80%, 0, 0);
    transform: translate3d(-80%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes inFromRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes inFromRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
