@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';
.logo-banner {
	width:100%;
	background-image:url('../../assets/images/iconography/iconography-hospital-banner-bg.svg'), linear-gradient(to right, #FFFFFF , #EFEFEF);
	background-repeat:no-repeat;
	background-position:right bottom;
	margin: -50px 0 80px 0;
  @media (min-width: 1650px) {
    margin: -50px 0 150px 0;
  }
	padding:30px 0px 50px 0px;  
	position:relative; 
  display: flex;

  .container {
    width:100%;
    padding:0 100px;
    position:relative;
   }
  .column {
	  float:left;
  }
  .left {
    float: left;
    width:30%;
    padding: 0;
  }
  .right {
    float: right;
	  width:70%;
  }
  .textarea {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #363533;
    margin: -18px 25px 0 21px;

  }
  .responsiveimage {
    float: right;
  }
  .logo-image {
    background-color:#4D104A;
    width:35%;
    position: absolute;
    text-align: center;
    bottom: -91px;
    @media screen and (max-width: 1116px){
      bottom:-130px;
    }
    img {
      width: 100%;
      margin: 43px auto;
      max-width: 320px;
      display: block;
    }
  }
  .colorbar {
    height:16px;
    width:45%;
    position:absolute;
    bottom:51px;
    right:0px;
    border-radius: 10px 0 0 10px;
    background-color:#4D104A;
  }
  .colorbar-mobile {
    display: none;
  }
}

@include max-screen(large) {
  .logo-banner {
    background-image: none;
    padding: 0px;
    margin: -50px 0 0 0;
    .container {
      padding: 0px;
    }
    .left {
      width: 100%;
      padding: 0px;
      margin: 0px;
      background-image:url('../../assets/images/iconography/iconography-hospital-banner-bg.svg'), linear-gradient(to right, #FFFFFF , #EFEFEF);
      background-repeat:no-repeat;
      background-position:right bottom;
      border-right: 18px solid white;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -ms-box-sizing: border-box;
      box-sizing: border-box;
    }
    .textarea {
      padding: 18px 28px 30px 18px;
    }
    .right {
      float:left;
      width: 100%;
      display: block;
    }    
    .responsiveimage {
      line-height: 0px;
      border-left: 18px solid white;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -ms-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0 18px 0 0;
    }
    .logo-image {
      margin: 60px 0 0 0;
      width:100%;
      height: initial;
      position: relative;
      float: left;
      bottom: initial;
      left: initial;
      img {
        margin: 43px auto;
        width: 100%;
      }
      picture {
        display: flex;
        width: 320px;
        margin: 0 auto;
      }
    }
    .colorbar-mobile {
      display: block;
      height: 16px;
      width:70%;
      position:absolute;
      right:0px;
      border-radius: 10px 0 0 10px;
      background-color:#4D104A;
    }
  }
  .colorbar {
    display:none;
  }
}