@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.skiptocontent {
  a {
    position: absolute;
    z-index: 90000;
    top: -1000px;
    left: 0;
    color: white;
    @include min-screen(large) {
      color: $upmc-purple;
    }
    &:focus {
      top: 0;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
