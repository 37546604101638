html,
body {
  -webkit-overflow-scrolling: touch;
}

html {
  overflow-y: scroll;
}

.hidden {
  display: none;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.Content-Author-Alert {
  color: red;
}
