@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.consultant-sitemap-list {
  @include min-screen(tiny) {
    columns: 1;
  }
  @include min-screen(large) {
    columns: 2;
  }

  .consultant-item {
    list-style-position: inside;
  }
}
.consultant-sitemap-header {
  @include min-screen(tiny) {
    padding-left: 2%;
  }
  @include min-screen(large) {
    padding-left: 2%;
  }
}
