@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.consultant-details {
  .consultant-banner-wrapper {
    display: block;
    @include min-screen(large) {
      display: flex;
      margin: 0 0 0 100px;
    }
    .consultant-banner-img {
      width: 100%;
      background: url('../../assets/images/iconography/iconography-white-lines-on-grey.svg')
          no-repeat bottom right,
        linear-gradient(108deg, #ffffff 0%, #efefef 100%) padding-box;
      @include min-screen(large) {
        width: 372px;
        background: none;
      }
      img {
        @include max-screen(large) {
          max-width: 65%;
          padding-left: 18px;
        }
        @include min-screen(large) {
          width: 372px;
          max-width: none;
        }
      }
    }
    .consultant-banner-details {
      width: 100%;
      @include min-screen(large) {
        width: 78%;
        margin: 30px 0;
        background: url('../../assets/images/iconography/iconography-white-lines-on-grey.svg')
            no-repeat bottom right,
          linear-gradient(108deg, #ffffff 0%, #efefef 100%) padding-box;
      }
      .consultant-banner-details-innner {
        padding: 18px;
        @include min-screen(large) {
          padding: 0 0 0 30px;
        }
        .consultant-contact-subheading,
        .consultant-location-heading {
          font-weight: 700;
        }

        .consultant-specialty-subheading {
          @include heading-font();
          @include font-size(2.8);
          display: block;
        }
        .consultant-locations-list {
          margin-top: 0px;
        }
        ul {
          list-style-type: none;
          margin: 20px 0 0;
          padding: 0;
        }
      }
    }
  }
  .consultant-biography {
    .consultant-biography-wrapper {
      padding: 0 18px;
      @include min-screen(large) {
        width: 870px;
        padding: 0;
        margin: 30px 0 30px 502px;
      }

      .consultant-schedule-subheading,
      .consultant-subSpecialty-subheading,
      .consultant-biography-heading,
      .consultant-research-subheading,
      .consultant-membership-subheading,
      .consultant-qulifications-subheading,
      .consultant-specialty-subheading,
      .consultant-subspecialty-subheading {
        @include heading-font();
        @include font-size(2.4); //mobile
        display: block;
        margin: 20px 0 0 0;
      }
      .consultant-schedule-subheading,
      .consultant-research-subheading,
      .consultant-membership-subheading {
        margin-bottom: 0;
      }
      .consultant-locations-list,
      .schedule-location-statement,
      .consultant-subspecialty-list,
      .consultant-research-list,
      .consultant-membership-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      .consultant-locations-list {
        .schedule-days-statement {
          margin-bottom: 20px;
        }
        li {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .schedule-location-statement {
        font-weight: bolder;
      }

      .consultant-qualifications-details {
        margin-top: 0;
        margin-bottom: 0;
      }

      p,
      .consultant-biography-details {
        margin-left: 0;
        p {
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
