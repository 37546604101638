.MobileNavigation {
  .mobile-menu-button {
    padding: 0;
    margin-left: 32px;
  }
}
.mobile-drawer {
  top: 80px !important;
  &.inner-drawer {
    top: 80px;
    min-width: 100vw;
  }
  .MuiDrawer-paper {
    top: 80px !important;
    width: 100%;
    height: calc(100% - 80px);
  }
  .MuiBox-root {
    min-width: 100%;
  }
}

.emergency-alert-wrapper {
 &+.toolbar{ 
  
  .mobile-drawer {
    top: 226px !important;
    &.inner-drawer {
      top: 226px;
    }
    .MuiDrawer-paper {
      top: 226px !important;
      width: 100%;
      height: calc(100% - 226px);
    }
  }
}}