@import '../../assets/styles/typography';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.marketing-alert-wrapper {
  padding: 19px 20px;
  @include min-screen(large) {
    padding: 23px 98px;
  }
  display: flex;
  transition: transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s;
  &.rose-red {
    background-color: $rose-red;
    .marketing-alert-carousel {
      button {
        color: $white;
      }
    }
  }
  &.grey {
    background-color: #ececec;
    .marketing-alert-carousel {
      button,
      button span {
        color: $cool-grey-11;
      }
    }
    .alert-close .marketing-alert-close .MuiIconButton-label .MuiSvgIcon-root {
      color: $cool-grey-11;
    }
  }
  &.light-orange {
    background-color: $light-orange;
    .marketing-alert-carousel {
      button,
      button span {
        color: $cool-grey-11 !important;
      }
    }
    .alert-close .marketing-alert-close .MuiIconButton-label .MuiSvgIcon-root {
      color: $cool-grey-11;
    }
  }
  &.medium-cyan {
    background-color: $medium-cyan;
    .marketing-alert-carousel {
      button {
        color: $white;
      }
    }
  }
  &.dark-cyan {
    background-color: $dark-cyan;
    .marketing-alert-carousel {
      button {
        color: $white;
      }
    }
  }
  &.dark-purple {
    background-color: $dark-purple;
    .marketing-alert-carousel {
      button {
        color: $white;
      }
    }
  }
  &.light-purple {
    background-color: $light-purple;
    .marketing-alert-carousel {
      button {
        color: #3c1053;
      }
    }
  }
  &.light-blue {
    background-color: $light-blue;
    .marketing-alert-carousel {
      button {
        color: $cool-grey-11;
      }
    }
  }
  &.yellow {
    background-color: $yellow;
    .marketing-alert-carousel {
      button {
        color: $cool-grey-11;
      }
    }
  }
  &.dark-orange {
    background-color: $dark-orange;
    .marketing-alert-carousel {
      button {
        color: $cool-grey-11;
      }
    }
  }
  &.coral {
    background-color: $coral;
    .marketing-alert-carousel {
      button {
        color: $white;
      }
    }
  }
  .marketing-alert-carousel {
    align-self: center;
    .previous-alert {
      padding: 0;
      margin-right: 15px;
      @include min-screen(large) {
        margin-right: 45px;
      }
    }
    .next-alert {
      padding: 0;
      margin-left: 15px;
    }
  }
  .marketing-alert {
    display: block;
    @include min-screen(large) {
      flex-direction: row;
      display: flex;
      align-items: center;
      height: inherit;
    }
    &.rose-red {
      background-color: $rose-red;
    }
    &.light-orange {
      background-color: $light-orange;
    }
    &.medium-cyan {
      background-color: $medium-cyan;
    }
    &.dark-cyan {
      background-color: $dark-cyan;
    }
    &.dark-purple {
      background-color: $dark-purple;
    }
    &.grey {
      background-color: #ececec;
    }
    &.light-blue {
      background-color: $light-blue;
    }
    &.yellow {
      background-color: $yellow;
    }
    &.dark-orange {
      background-color: $dark-orange;
    }
    &.coral {
      background-color: $coral;
    }
    &.grey,
    &.light-blue,
    &.yellow,
    &.dark-orange,
    &.coral,
    &.light-orange {
      .alert-details {
        color: $cool-grey-11;
        .alert-content {
          .alert-link {
            a {
              color: $cool-grey-11;
            }
          }
        }
      }
      .alert-title {
        p {
          color: $cool-grey-11;
        }
      }
      .alert-close {
        .marketing-alert-close {
          .MuiSvgIcon-root {
            color: $cool-grey-11 !important;
          }
        }
      }
    }
    &.light-purple {
      background-color: $light-purple;
      .alert-details {
        color: #3c1053;
        .alert-content {
          .alert-link {
            a {
              color: #3c1053;
            }
          }
        }
      }
      .alert-title {
        p {
          color: #3c1053;
        }
      }
      .alert-close {
        .marketing-alert-close {
          .MuiSvgIcon-root {
            color: #3c1053 !important;
          }
        }
      }
    }

    .alert-details {
      display: block;
      @include min-screen(large) {
        flex-direction: row;
        display: flex;
        align-items: center;
      }
      @include body-font();
      font-weight: 500;
      color: $white;
      font-size: 18px;
      .alert-content {
        display: block;
        margin: 20px 0;

        @include min-screen(large) {
          display: flex;
          min-height: initial;
          align-items: center;
          margin: 0;
        }
        .alert-message {
          display: block;
          margin-top: 0;
          @include min-screen(large) {
            display: inline-block;
            margin-right: 0;
          }
          .description p {
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .alert-link {
          display: block;
          @include min-screen(large) {
            display: inline-block;
            margin-left: 20px;
            width: 280px;
          }
          a {
            color: $white;
          }
        }
      }
    }
    .alert-close {
      margin-left: 0;
      position: absolute;
      top: -10px;
      right: 0;
      @include min-screen(large) {
        margin-left: auto;
        top: inherit;
        right: inherit;
        position: relative;
      }
      &.multiple-close {
        right: -48px;
        @include min-screen(large) {
          right: inherit;
        }
      }
      .marketing-alert-close {
        font-size: initial;
        padding: 0;
        .MuiIconButton-label {
          display: block;
          .MuiSvgIcon-root {
            font-size: 3.5rem;
            color: $white;
          }
        }
      }
    }
  }
  .item {
    width: 100%;
    display: none;
    opacity: 0;
    transition-duration: 1s ease;
    & + .item {
      display: none;
    }
    &.active {
      display: block;
      opacity: 1;
      transition-duration: 1s;
      transform: scale(1);
    }
  }
}
