@import '../../assets/styles/typography';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.header {
  nav {
    //Grow helper
    .grow {
      flex-grow: 1;
    }
    //Utility Navigation Section
    .utility-navigation {
      align-content: flex-end;
      align-items: center;
      padding: 10px 98px;
      background-color: #fff;
      display: none;
      @include min-screen(large) {
        display: flex;
      }
      .utility-inner-wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .utility-nav-items {
          .linklistcontainer {
            .linklist {
              list-style: none;
              margin: 0;
              padding: 0;
              .linklistitem {
                display: inline-block;
                margin-right: 36px;
                &:last-child {
                  margin: 0 32px 0 0;
                }
                a {
                  @include heading-font();
                  font-weight: 700;
                  @include font-size(1.6);
                  color: $cool-grey-11;
                  text-decoration: none;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
        .search-box-container {
          width: 265px;
          .search-field-wrapper {
            border: none;

            .search-field-border {
              border: none;
              padding: 0px;
              background: none;
              .headless-search-field-input {
                width: calc(100% - 40px);
              }
              .site-search-reset-button {
                display: none;
              }
            }
            &:focus-within{
              .site-search-reset-button{
                display: inline-block;
              }
              .headless-search-field-input{
                width: calc(100% - 79px);
              }
            }
          }
        }
      }
    }
    //End Utility Navigation Section
    .nav-height {
      height: 80px;
      @include min-screen(large) {
        height: 100px;
      }
      .appbar {
        background: linear-gradient(99deg, rgba(77, 16, 74, 1) 0%, rgba(43, 6, 34, 1) 100%);
        box-shadow: none !important;
        align-content: flex-start;
        border-bottom: 10px solid #228747;
        @include min-screen(large) {
          position: relative;
          overflow: hidden;
        }

        .toolbar {
          min-height: inherit;
          align-items: center;
          justify-content: center;
          padding: 19px 20px;
          border-bottom: 1px solid #fff;
          @include min-screen(large) {
            padding: 23px 98px;
            position: relative;
          }
          // Desktop Main Navigation
          .section-desktop {
            display: none;
            @include min-screen(large) {
              display: flex;
              justify-content: flex-end;
              flex-grow: 1;
            }
            .desktop-nav-items {
              .linklistcontainer {
                .linklist {
                  list-style: none;
                  margin: 0;
                  padding: 0;
                  .linklistitem {
                    margin-right: 36px;
                    display: inline-block;
                    &:last-child {
                      margin-right: 0;
                    }
                    a {
                      @include heading-font();
                      @include font-size(1.7);
                      position: relative;
                      color: $white;
                      text-decoration: none;
                      &:hover {
                        color: $white;
                        &::before {
                          transform: scaleX(1);
                        }
                      }
                      &::before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                        background-color: $white;
                        transform: scaleX(0);
                        transform-origin: top left;
                        transition: transform 0.3s ease;
                      }
                    }
                  }
                }
              }
            }
          }
          // End Desktop Main Navigation
          // Logo Section
          .logo-wrapper {
            justify-self: flex-start;
            width: 80px;
            margin-right: 24px;
            @include min-screen(large) {
              width: 136px;
              margin-right: 36px;
            }
            .logo {
              img {
                max-width: 100%;
                height: auto;
                min-width: 80px;
                vertical-align: middle;
              }
            }
          }
          //End Logo Section
          .section-mobile {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            @include min-screen(large) {
              display: none;
            }

           // Mobile Search Section
           .mobile-header-search-wrapper {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-content: center;
            width: 100%;
            height: 31px;
            .search-box-container {
              width: 100%;
              .search-field-wrapper {
                border: none;
                display: flex;
                width: 80%;
                margin: auto;
                max-width: 600px;
                .search-field-border {
                  width: 100%;
                  padding: 0px;
                  background: none;
                  border: none;
                  .headless-search-field-input {
                    width: calc(100% - 60px);
                  }
                  .site-search-reset-button{
                    display: none;
                  }
                }
                &:focus-within {
                  width: 94vw;
                  margin-right: 500px; 
                  margin-left: 460px;
                  max-width: none;
                  .search-field-border{
                    .search-field-frame{
                      .site-search-reset-button{
                        display: inline-block;
                      }
                      .headless-search-field-input{
                        width: calc(100% - 100px);
                      }
                      .cancel-search{
                        display:flex;
                        margin: auto 0;
                        margin-left: 10px;
                        &:hover{
                          cursor: pointer;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          //End Mobile Search Section
        } //End Mobile Section
        }
      }
    }
  }
}
.emergency-alert-desktop {
  display: none;
  @include min-screen(large) {
    display: block;
  }
}
header > .emergency-alert-wrapper {
  display: block;
  @include min-screen(large) {
    display: none;
  }
}

.emergency-alert-wrapper {
  & + .toolbar {
    .mobile-drawer {
      top: 226px !important;
      &.inner-drawer {
        top: 226px;
      }
      .MuiDrawer-paper {
        top: 226px !important;
        width: 100%;
        height: calc(100% - 226px);
      }
    }
  }
}
