.two-column-with-left-nav {
  padding: 0 18px;

  h1 {
    margin-left: 0px;
    margin-top: -6px;
  }
  @media (min-width: 1263px) {
    padding: 0 100px;
  }
}
