@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.job-listing {
  @include min-screen(tiny) {
    padding: 0 17.5px;
  }
  @include min-screen(large) {
    max-width: calc(90% - 100px);
    padding: 0 100px;
  }
  .error-message,
  .no-results-meesage,
  .loading-message {
    @include h2();
  }
}
.job-posting-summary {
  padding-top: 22.5px;
  border-bottom: 1px solid $cool-grey-4;
  &:last-child {
    border-bottom: none;
  }
  .title {
    margin: 0;
    @include max-screen(medium) {
      font-size: 36px;
    }
    @include max-screen(small) {
      font-size: 32px;
    }
  }
  .department {
    @include h5();
    margin: 0;
    color: $cool-grey-11;
  }
  .details-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    .details-text-wrapper {
      margin-right: 25px;
      .details-text-location-wrapper,
      .details-text-shift-wrapper {
        @include min-screen(medium) {
          display: inline-block;
        }
      }
      .details-text-location-wrapper {
        vertical-align: top;
        @include min-screen(medium) {
          min-width: 550px;
        }
      }
      .details-text-shift-wrapper {
        p {
          display: block;
        }
        @include min-screen(medium) {
          width: 250px;
        }
      }
    }
    a {
      text-decoration: none;
      button {
        margin: 15px 0;
        width: 196px;
        height: 50px;
      }
    }
    p {
      margin: 0;
    }
    @include max-screen(small) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
