@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';
@import 'styles/_base.scss';
@import 'styles/_typography.scss';
@import 'styles/_accessibility.scss';
@import 'styles/_utility.scss';
@import 'styles/_buttons.scss';

/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData,
.scpm {
  display: none !important;
}